@import "setting";

.slider {
	position: relative;
	overflow: hidden;

	&__main{
		height: 85vh;
		position: relative;

		@include mq-down(xs){
			height: 70vh;
		}

		.pc-mainv{
			height: 85vh;

			@include mq-down(xs){
				height: 70vh;
			}

		}
	}

	@include mq-down(xs){
		margin: 70px 0 0;
	}
}

.slider .index {
	img {
		transition: transform 5.5s;
		transition-timing-function: linear;
	}
	.img0{
		transform: scale(1.16);
		&.active{
			transform: scale(1);
		}
	}
	.img1 {
		transform: scale(1.16);
		&.active{
			transform: scale(1);
		}
	}
	.img2 {
		transform: scale(1.16);
		&.active{
			transform: scale(1);
		}
	}
	.img3{
		transform: scale(1.16);
		&.active{
			transform: scale(1);
		}
	}
}


.interval{
	padding:80px 0;

	@include mq-down(sm) {
		padding:30px 0;
	}
}

.sub-lead{
	@extend .interval;

	&__title{
		@extend .lead-tc;
		@include font-size(36);
		margin: 0;
	}

	&__lead{
		@extend .lead-tc;
		margin: 30px 0 0;
		line-height: 2.4;
		br{
			@include mq-down(sm) {
				display: none;
			}
		}
	}
}

.news{
	background: #2d2d35;
	@extend .interval;

	ul{
		padding: 10px;

		@include mq-down(sm) {
			padding: 0;
		}

		li{
			list-style: none;

			dl{
				border-bottom: solid 1px #fff;
				padding: 10px 5px;

				@include mq-down(sm) {
						padding: 0 0 10px;
						margin: 0 0 10px;
				}

				dt{
					float: left;

					@include mq-down(sm) {
						float: none;
					}

				}

				dd{
					float: left;
					padding: 0 20px;

					@include mq-down(sm) {
						float: none;
						padding: 0;
					}

				}
			}


		}
	}

	&__title{
		@extend .lead-tc;

		@include mq-down(sm) {
			margin: 0 0 15px !important;
		}

	}
}

.contens-area{
	@extend .interval;

	&__frame{
		margin-bottom: 200px;

		@include mq-down(sm) {
			margin-bottom: 0;
		}

		&__box{
			z-index: 2;

			@keyframes animation01{
				0% {
					width: 0;
				}
				100% {
					width: 100%;
				}
			}

			@keyframes animation02{
				0% {
					height: 0;
				}
				100% {
					height: 100%;
				}
			}

			&__border_tb{
				position: relative;

				&:before {
					content: '';
					width: 0;
					height: 10px;
					background: rgba(255, 255, 255,0.5);
					position: absolute;
					top: 0;
					left: 0;
					backface-visibility: hidden;
					z-index: 2;
					//animation: animation01 0.5s linear;
				}

				&:after {
					content: '';
					width: 10px;
					height: 0;
					background: rgba(255, 255, 255,0.5);
					position: absolute;
					top: 0;
					left: 0;
					backface-visibility: hidden;
					z-index: 2;
					//animation: animation02 0.5s linear;
				}
			}

			&__border_tb_active{

				&:before {
					width: 100%;
					animation: animation01 0.5s linear;
					z-index: 2;
				}

				&:after {
					height: 100%;
					animation: animation02 0.5s linear;
					z-index: 2;
				}

			}

			&__border_rl{
				position: relative;

				&:before {
					content: '';
					width: 0;
					height: 10px;
					background: rgba(255, 255, 255,0.5);
					position: absolute;
					bottom: 0;
					right: 0;
					backface-visibility: hidden;
					z-index: 2;
				}

				&:after {
					content: '';
					width: 10px;
					height: 0;
					background: rgba(255, 255, 255,0.5);
					position: absolute;
					bottom: 0;
					right: 0;
					backface-visibility: hidden;
					z-index: 2;
				}

			}

			&__border_rl_active{

				&:before {
					width: 100%;
					animation-delay: -2s;
					animation: animation01 0.5s linear;
					z-index: 2;
				}

				&:after {
					height: 100%;
					animation-delay: -2s;
					animation: animation02 0.5s linear;
					z-index: 2;
				}

			}


			&__item{
				line-height: font-height(30,16);
				padding: 10px 30px 30px;
				//border:solid 10px rgba(255, 255, 255,0.5);
				min-width: 500px;
				min-height: 280px;
				position: relative;

				@include mq-down(sm) {
					min-width: 80%;
					min-height: 300px;
					padding: 10px 10px 30px;
				}

				button[type="more"]{
					position: absolute;
					bottom: 33px;
					right: 64px;
					z-index: 1;
				}

				// &:before {
				// 	top: -10px !important;
				// 	left: -10px !important;
				// 	width: 104% !important;
				// 	height: 107% !important;

				// 	@include mq-down(sm) {
				// 		width: 107% !important;
				// 	}
				// }

			}

			&__lead{
				br{
					@include mq-down(sm) {
						display: none;
					}
				}
			}

		}

		.techno{
			@include poslt (-50,60);

			@include mq-down(sm) {
				position: static;
				margin-bottom: 50px;
			}

			h2{
				padding-left: 40px;

				@include mq-down(sm) {
					padding-left: 0;
				}
			}

			.contens-area__frame__box__item {
				@include mq-down(sm) {
					background: url('../img/top/contens-area-techo_sp.png') center center no-repeat;
					padding: 15px;
				}
			}
		}

		.produ{
			@include posrt (-50,60);

			@include mq-down(sm) {
				position: static;
				margin-bottom: 50px;
			}

			.contens-area__frame__box__item {
				@include mq-down(sm) {
					background: url('../img/top/contens-area-prod_sp.png') center center no-repeat;
					padding: 15px;
				}
			}
		}

	}

	&__recrut{
		background: url(../img/top/contens-area-receuit.png) center center no-repeat;
		background-size: cover;
		position: relative;
		width: 100%;
		min-height: 397px;

		@include mq-down(sm) {
			min-height: 200px;
		}

		&__item{
			width: 170px;
			text-align: center;
			@extend .block-center;

			.hd-en{
				padding-bottom: 30px;
			}
		}
	}

	&__company{
		margin-top: 80px;
		background: url(../img/top/contens-area-company.png) center center no-repeat;
		box-shadow: 0 0 0 10px rgba(255, 255, 255,0.5) inset;
		box-sizing: border-box;
		background-size: cover;
		position: relative;
		width: 100%;
		min-height: 250px;

		@include mq-down(sm) {
			margin-top: 40px;
		}

		&__item{
			width: 170px;
			text-align: center;
			@extend .block-center;

			.hd-en{
				padding-bottom: 10px;
			}
		}
	}
}

.scroll_down{
	position: absolute;
	bottom: 20px;
	left:47%;
	@include mq-down(sm) {
		left:40%;
	}
}
